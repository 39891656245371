@font-face {
  font-family: "UfcSecondaryFont";
  src: url("/public/Share/Share-Bold.ttf");
  src: url("/public/Share/Share-BoldItalic.ttf");
  src: url("/public/Share/Share-Italic.ttf");
  src: url("/public/Share/Share-Regular.ttf");
}

@font-face {
  font-family: "UfcFont";
  src: url("/public/Teko/Teko-Bold.ttf");
  src: url("/public/Teko/Teko-Light.ttf");
  src: url("/public/Teko/Teko-Medium.ttf");
  src: url("/public/Teko/Teko-Regular.ttf");
  src: url("/public/Teko/Teko-SemiBold.ttf");
}

@font-face {
  font-family: "Newfont";
  src: url("/public/UFC-sternbach-font-betterstudio.com/Sternbach-Bold-Italic.otf");
  font-style: bold;
}

body {
  margin: 0;
  background-color: #191a20 !important;
  font-weight: 500;
  font-family: "UfcFont", sans-serif;
  color: #191919;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
